<template>
  <div class="runInfo actcpopup">
    <div class="popup">
      <div class="flex justify-center px-2">
        <div class="pt-3 font-bold" style="font-size: 12px;">RIG STATE</div>
        <!-- <div class="close_popup" @click="$emit('close_actc')">X</div> -->
      </div>

      <div class="p-5 text-xs flex flex-col">
        <div class="flex justify-between items-center">
          <label class="font-semibold uppercase">From</label>

          <Datepicker
            class="actc_datepicker"
            v-model="fromTime"
            :minDate="
              new Date(scaleFromDate).toLocaleString('en-US', {
                timeZone: timeZone,
              })
            "
            :maxDate="
              new Date(scaleToDate).toLocaleString('en-US', {
                timeZone: timeZone,
              })
            "
            textInput
            :dark="darkDark != 'white'"
            placeholder="MM/DD/YYYY, HH:MM:SS"
            :enableTimePicker="true"
            locale="en-US"
            :enable-seconds="true"
            @update:modelValue="(e) => onDateChangeHandler('from', e)"
          />
        </div>
        <div class="flex justify-between items-center pt-2">
          <label class="font-semibold uppercase">To</label>
          <Datepicker
            class="actc_datepicker"
            v-model="toTime"
            :minDate="
              new Date(scaleFromDate).toLocaleString('en-US', {
                timeZone: timeZone,
              })
            "
            :maxDate="
              new Date(scaleToDate).toLocaleString('en-US', {
                timeZone: timeZone,
              })
            "
            textInput
            :dark="darkDark != 'white'"
            placeholder="MM/DD/YYYY, HH:MM:SS"
            :enableTimePicker="true"
            locale="en-US"
            :enable-seconds="true"
            @update:modelValue="(e) => onDateChangeHandler('to', e)"
          />
        </div>
        <div class="flex justify-between items-center pt-2">
          <label class="font-semibold uppercase">ACTC</label>
          <!-- <select
            v-model="selectedACTC"
            class="fieldHeight rounded text-xxxs text-white focus:outline-none focus-visible:outline-none px-2"
            style="
              background: var(--searchBarBg);
              color: var(--searchColor);
              width: 70%;
              border: 1px solid #dde1e4;
            "
          >
            <option
              v-for="actc in actCodes"
              :key="actc.code"
              :value="actc.code"
            >
              {{ actc.name }}
              <span
            :style="{
              color: actc.color,
              fontSize: '3.5em',

            }"
            >&#8226;</span>
            <imput type="radio" />
            </option>
            <option disabled="disabled">-------------------</option>
            <option value="miscellaneous">Miscellaneous</option>
            <option disabled="disabled">-------------------</option>
          </select> -->
          <div style="height: 25px; width: 70%">
            <CostomSelect
              :lists="actc_list"
              display-prop="displayName"
              value-prop="actcCode"
              color-prop="colorCode"
              :choosed="selectedHandler"
              :selected_value="selectedACTC"
              :key="custom_select_refresh_key"
            />
          </div>
        </div>
        <div
          v-if="selectedACTC == 'miscellaneous'"
          class="flex justify-between items-center pt-2"
        >
          <label class="font-semibold uppercase">Text</label>
          <input
            type="text"
            v-model="manually_entered_name"
            style="
              background: var(--searchBarBg) !important;
              color: var(--searchColor) !important;
              width: 70%;
              border: 1px solid #dde1e4;
              height: 25px;
              border-radius: 0.25rem;
              padding-left: 10px;
              border: 0px;
            "
          />
        </div>

        <div class="flex justify-end mt-4">
          <button
            class="text-xxs font-semibold border"
            style="
              padding: 3px 8px;
              outline: none;
              color: var(--activeTextColor);
              border-color: currentColor;
            "
            @click="saveACTCHandler"
          >
            SUBMIT
          </button>
          <!-- <button
            class="text-xxs font-semibold"
            style="
              padding: 3px 8px;
              border: 1px solid #fda900;
              color: #fda900;
              outline: none;
              margin-left: 10px;
            "
          >
            Delete
          </button> -->
          <button
            class="text-xxs font-semibold"
            style="
              padding: 3px 8px;
              border: 1px solid #f40303;
              outline: none;
              color: #f40303;
              margin-left: 10px;
            "
            @click="$emit('close_actc')"
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "@vuepic/vue-datepicker";
import actCode from "./actcCode.json";
import CostomSelect from "./CustomSelect.vue";
import moment from "moment-timezone";
import apiService from "../../../api/services";
export default {
  components: {
    Datepicker,
    CostomSelect,
  },
  props: {
    scaleFromDate: {
      type: Number,
    },
    scaleToDate: {
      type: Number,
    },
    timeZone: {
      type: String,
      default:'CST6CDT'
    },
    wellId: {
      type: String,
    },
    wellboreId: {
      type: String,
    },
    actc_list:{
      type: Array,
      default: [],
    },
    active_actc:{
      type: [Object, String],
      default: '',
    },
    displayName:{
      type: String,
      default: '',
    }
  },
  data() {
    return {
      actCodes: actCode,
      selectedACTC: "",
      fromTime: "",
      toTime: "",
      manually_entered_name: "",
      custom_select_refresh_key: 1,
    };
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    selectedHandler(val) {
      if (val && val.length > 0) {
        this.selectedACTC = val[0].actcCode;
      }
    },
    onDateChangeHandler(forDate, e) {
// console.log(e,this.scaleFromDate,'------' ,moment(new Date(this.fromTime).toISOString().slice(0, 19)).unix() * 1000 - (new Date().getTimezoneOffset()*60000), moment(new Date(e).toISOString().slice(0,19)).format('MM/DD/YYYY hh:mm:ss a'))
if(forDate === 'from'){
        // this.fromTime = moment(e).unix() * 1000;
        if(moment(new Date(this.fromTime).toISOString().slice(0, 19)).unix() * 1000 - (new Date().getTimezoneOffset()*60000) < this.scaleFromDate){
          this.fromTime = "";
          this.$toast.clear();
          this.$toast.error(`From-time should not be lower than ${moment(this.scaleFromDate).format('MM/DD/YYYY HH:mm:ss ')}`, {
            position: "top",
            duration: 3000,
          });
          return;
        }
        else if(moment(new Date(this.fromTime).toISOString().slice(0, 19)).unix() * 1000 - (new Date().getTimezoneOffset()*60000) > this.scaleToDate){
          this.fromTime = "";
          this.$toast.clear();
          this.$toast.error(`From-time should not be higher than ${moment(this.scaleToDate).format('MM/DD/YYYY HH:mm:ss ')}`, {
            position: "top",
            duration: 3000,
          });
          return;
        }
      }else{
        // this.toTime = moment(e).unix() * 1000;
        if(moment(new Date(this.toTime).toISOString().slice(0, 19)).unix() * 1000 - (new Date().getTimezoneOffset()*60000) < this.scaleFromDate){
          this.toTime = "";
          this.$toast.clear();
          this.$toast.error(`To-time should not be lower than ${moment(this.scaleFromDate).format('MM/DD/YYYY HH:mm:ss ')}`, {
            position: "top",
            duration: 3000,
          });
          return;
        }
        else if(moment(new Date(this.toTime).toISOString().slice(0, 19)).unix() * 1000 - (new Date().getTimezoneOffset()*60000) > this.scaleToDate){
          this.toTime = "";
          this.$toast.clear();
          this.$toast.error(`To-time should not be higher than ${moment(this.scaleToDate).format('MM/DD/YYYY HH:mm:ss ')}`, {
            position: "top",
            duration: 3000,
          });
          return;
        }
      }
    },
    async saveACTCHandler() {
      if (this.fromTime == "") {
        this.$toast.clear();
        this.$toast.error("Select start time", {
          position: "top",
          duration: 3000,
        });
        return;
      }
      // console.log('lll', new Date(this.fromTime).toISOString().slice(0, 16), moment(new Date(this.fromTime).toISOString().slice(0, 16)).unix() * 1000,moment(new Date(this.fromTime).toISOString().slice(0, 16)).format('MM/DD/YYYY hh:mm:ss a'), 'to', moment(new Date(this.toTime).toISOString().slice(0, 16)).unix() * 1000,moment(new Date(this.toTime).toISOString().slice(0, 16)).format('MM/DD/YYYY hh:mm:ss a'))
      if(this.fromTime && this.toTime && moment(new Date(this.fromTime).toISOString().slice(0, 19)).unix() * 1000 >= moment(new Date(this.toTime).toISOString().slice(0, 19)).unix() * 1000){
        this.$toast.clear();
        this.$toast.error("To-time should be higher than from-time", {
          position: "top",
          duration: 3000,
        });
        return;
      }
      if (this.selectedACTC == "") {
        this.$toast.clear();
        this.$toast.error("Select ACTC", {
          position: "top",
          duration: 3000,
        });
        return;
      }
      const payload ={
        'startTime':moment(new Date(this.fromTime).toISOString().slice(0, 19)).unix() * 1000 - (new Date().getTimezoneOffset()*60000),
        'endTime': this.toTime ? moment(new Date(this.toTime).toISOString().slice(0, 19)).unix() * 1000 - (new Date().getTimezoneOffset()*60000) : '',
        'actc':this.selectedACTC,
        // 'customerName':this.$store.state.data.customer,
        'wellId':this.wellId,
        'wellboreName':this.wellboreId,
      }
      if(this.selectedACTC == 'miscellaneous'){
        if(!this.manually_entered_name){
          this.$toast.clear();
          this.$toast.error("Please enter the text", {
            position: "top",
            duration: 3000,
          });
          return;
        }
        payload['miscellaneousActivityName'] = this.manually_entered_name;
        payload['actc'] = 40;
      }
      let result = await apiService.actc.actc_save(payload)
      if(result.status == 200){
        // this.$emit('close_actc');
        if(this.displayName){
          let loki_db_payload = {
            display_name: this.displayName.replace(/\s/g, "").toLowerCase(),
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          }
          await apiService.actc.clear_from_lokidb(loki_db_payload);
        }
        this.$toast.clear();
        this.$toast.success("ACTC saved successfully", {
          position: "top",
          duration: 3000,
        });
        this.$emit('fetch_data');
        this.$emit('close_actc');
      }
      // console.log('valid data',payload);
    },
  },
  mounted(){
    moment.tz.setDefault(this.timeZone);
    if(this.active_actc){
      this.fromTime = moment(this.active_actc.start_time).format().slice(0, 19);
      this.selectedACTC = this.active_actc.misc_activity_name ? 'miscellaneous' : this.active_actc.actc;
      this.custom_select_refresh_key += 10;
      this.manually_entered_name = this.active_actc.misc_activity_name ? this.active_actc.misc_activity_name : '';
    }
  }
};
</script>
<style scoped>
.runInfo {
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}
.popup {
  position: absolute;
  top: 40%;
  left: 50%;
  /* background: rgba(0, 0, 0); */
  /* background: var(--colorBoxPopUpBg); */
  background: var(--sidebarbg);
  color: var(--textColor);
  transform: translate(-50%, -40%);
  width: 250px;
}
.popup input,
.popup select {
  font-weight: 600;
  background: var(--sidebarListBgColor) !important;
  font-size: calc(0.25vw + 0.2vh + 0.55vmin);
}
.popup button {
  font-size: calc(0.25vw + 0.2vh + 0.55vmin);
}
.fieldHeight {
  height: 25px;
}
.close_popup:hover {
  color: red;
  cursor: pointer;
}
.actc_datepicker,
.dp__input {
  background: var(--colorBoxPopUpInputBg) !important;
  color: var(--textColor) !important;
  max-width: 70%;
  height: 25px !important;
}
.dp__input {
  height: 100% !important;
}
.actc_datepicker,
.dp__input,
.dp__input_icon_pad {
  height: 25px !important;
}
</style>
