<script setup>
import { toRefs, ref , onMounted} from "vue";

const props = defineProps({
  lists: {
    type: Array,
    require: true,
    default: [],
  },
  displayProp: {
    type: String,
    require: true,
    default: "full_name",
  },
  valueProp: {
    type: String,
    require: true,
    default: "user_id",
  },
  colorProp: {
    type: String,
    require: true,
    default: "",
  },
  multiple: {
    type: Boolean,
    require: false,
    default: false,
  },
  choosed: {
    type: Function,
    require: false,
  },
  selected_value:{
    type: [Number, String],
    default: ''
  },
});
const { lists, choosed, displayProp, valueProp, multiple, colorProp, selected_value } = toRefs(props);
const isShowDrow = ref(false);
const selected = ref([]);
const searchText = ref("");
onMounted(() => {
  if(selected_value.value){
    if(lists.value && lists.value?.length){
      let found = lists.value?.find(each=> each[valueProp.value] == selected_value.value)
      if(found){
        selectHadler(found);
      }
    }
  };
});
const selectHadler = (det) => {
  //check if available
  const ind = selected.value.findIndex(
    (e) => e[valueProp.value] == det[valueProp.value]
  );
  if (ind == -1) {
    if (multiple.value) {
      selected.value.push(det);
    } else {
      selected.value = [];
      selected.value.push(det);
      isShowDrow.value = false;
    }
    choosed.value(selected.value);
  } else {
    selected.value.splice(ind, 1);
    choosed.value(selected.value);
    isShowDrow.value = false;
  }
};
const checkedSelected = (id) => {
  //check if available
  const ind = selected.value.findIndex((e) => e[valueProp.value] == id);
  if (ind == -1) {
    return false;
  } else {
    return true;
  }
};
</script>
<template>
  <div class="full_width_height flex flex-col items-center h-64 mx-auto">
    <div class="full_width_height">
      <div class="flex flex-col items-center relative full_width_height">
        <div class="full_width_height">
          <div class="full_width_height">
            <button
              @click="isShowDrow = !isShowDrow"
              class="cursor-pointer full_width_height flex justify-between items-center px-2"
              style="
                background: var(--searchBarBg);
                color: var(--searchColor);
                border-radius: 0.25rem;
                border: 0px;
              "
            >
              <span class="font-bold">{{
                selected && selected.length > 0
                  ? selected.map((v) => v[displayProp]).toString()
                  : "select"
              }}</span>
              <svg
                v-if="!isShowDrow"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>

        <div
          :class="isShowDrow ? '' : 'hidden'"
          class="absolute shadow top-100 z-40 lef-0 rounded max-h-select overflow-y-auto w-full"
          style="background: var(--searchBarBg); color: var(--searchColor)"
        >
          <div class="flex flex-col full_width_height" ref="dropdownListRef">
            <div
              class="flex w-full items-center border-transparent relative hover:border-teal-100 full_width_height py-2"
            >
              <div class="dropdown_list">
                <div class="text-md h-full">
                  <input
                    type="text"
                    class="w-full h-full rounded-md h-7 border px-2"
                    style="
                      background: var(--searchBarBg);
                      color: var(--searchColor);
                      padding-right: 25px;
                    "
                    v-model="searchText"
                    @input=""
                  />
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 absolute" style="right: 10px;"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
              </div>
            </div>
            <template v-for="list in lists" :key="list[valueProp]">
              <div
                v-if="
                  list[displayProp] &&
                  list[displayProp]
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) > -1
                "
                class="cursor-pointer border-gray-100 width_min list_hover_sty"
              >
                <div
                  @click="selectHadler(list)"
                  class="flex w-24 items-center border-transparent relative hover:border-teal-100 full_width_height"
                >
                  <div class="dropdown_list">
                    <div
                      class="text-md"
                      :style="{
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '5px',
                        borderLeftColor: list[colorProp],
                      }"
                    >
                      <div class="pl-1">{{ list[displayProp] }}</div>
                    </div>
                    <div v-if="checkedSelected(list[valueProp])">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.top-100 {
  top: 100%;
}
.bottom-100 {
  bottom: 100%;
}
.max-h-select {
  max-height: 300px;
}
.width_min {
  /* width: 150px; */
  height: 25px;
}
.dropdown_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
}
.full_width_height {
  width: 100%;
  height: 100%;
}
.list_hover_sty:hover {
  background: rgba(70, 236, 241, 0.671);
}
</style>
